import * as React from "react"
import PropTypes from "prop-types"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import 'bootstrap/dist/css/bootstrap.min.css';
import {VscGithub} from 'react-icons/vsc'
import { FaLinkedin } from 'react-icons/fa'
import { AiFillMediumCircle, AiOutlineMail } from 'react-icons/ai'


const Header = () => (
  
  <Navbar className="z-front" expand="sm" style={{marginBottom: '30px'}}>
    <Navbar.Brand href="/" style={{color: '#fff'}}>
      J. Rosenthal
    </Navbar.Brand>

    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto" id="main-nav">
        <Nav.Link href="/about" style={{color: '#fff'}}>About</Nav.Link>
        <Nav.Link href="/projects" style={{color: '#fff'}}>Projects</Nav.Link>
        <Nav.Link href="/blog" style={{color: '#fff'}}>Blog</Nav.Link>
      </Nav>
    </Navbar.Collapse>
    
    <Navbar.Brand 
      style={{color: '#fff'}}
      className="ml-auto"
      href="https://github.com/JulianAR97"
      target="_blank"
    >
      <VscGithub />
    </Navbar.Brand>
    
    <Navbar.Brand 
      style={{color: '#fff'}}
      href="https://linkedin.com/in/julian-a-rosenthal"
      target="_blank"  
    >
      <FaLinkedin />
    </Navbar.Brand>

    <Navbar.Brand 
      style={{color: '#fff'}}
      href="https://julianrosenthal.medium.com/"
      target="_blank"
    >
      <AiFillMediumCircle />
    </Navbar.Brand>
    <Navbar.Brand 
      style={{color: '#fff'}}
      href="mailto:julian.alexander.rosenthal@gmail.com"
      target="_blank"
    >
      <AiOutlineMail />
    </Navbar.Brand>

    <Navbar.Toggle 
      className="navbar-dark"
      aria-controls="basic-navbar-nav" 
    />
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
